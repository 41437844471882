.successModal {
  background: rgba(0, 0, 0, 0.6);
  @apply fixed top-0 left-0 flex justify-center items-center h-screen w-full min-w-[375px] z-50 backdrop-blur-sm;

  &__container {
    background: linear-gradient(311.63deg, #000000 29.54%, #212121 74.97%);
    box-shadow: 0px 14px 44px rgba(46, 29, 58, 0.51);
    box-shadow: 0px 14px 44px rgba(46, 29, 58, 0.51);
    border: 1px solid rgba(255, 255, 255, 0.3);
    @apply flex flex-col items-center md:px-10 md:py-8 p-4 overflow-y-auto max-w-3xl w-[calc(100%-30px)] md:rounded-[30px] rounded-2xl absolute z-[1];
  }
  &__title {
    background: linear-gradient(
      100.42deg,
      #ffffff 13.83%,
      #67d8ff 58.52%,
      #95f6d2 91.13%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @apply text-f45 text-center font-semibold;
  }
  &__description {
    @apply text-center text-silver-sand text-sm font-light mt-3;
  }
  &__send-btn {
    background: linear-gradient(
      332.73deg,
      #d3d3d3 34.02%,
      #ffffff 70.97%
    ) !important;
    @apply md:rounded-2xl rounded-lg text-center w-full md:h-[75px] h-12 md:text-f32 text-sm text-black relative top-0 duration-300 hover:top-[-3px] outline-0;
  }
  &__close {
    @apply absolute md:right-10 right-3 md:top-8 top-3 w-5 h-5;
    span {
      @apply w-[19px] h-[4px] rounded absolute bg-white right-0 top-2 duration-300 first:-rotate-45 last:rotate-45;
    }
    &:hover {
      span {
        @apply first:rotate-45 last:rotate-[315deg];
      }
    }
  }
}
