.wellcome {
  @apply flex flex-col w-full min-h-screen py-12;
  &-bg {
    background: url("../../assets/images/wellcome-bg.jpg") no-repeat center
      center / cover;
  }
  &__flexcenter {
    @apply flex items-center justify-center flex-col;
  }
  h2 {
    @apply md:text-f45 text-f32 text-monsoon text-center mt-3;
  }
  p {
    @apply text-sm mt-3 text-center;
  }
  h3 {
    @apply text-f32 font-semibold text-center;

    background: linear-gradient(
      to right,
      #ffffff 13.83%,
      #67d8ff 48.52%,
      #95f6d2 91.13%,
      #ffffff 100%
    );
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3.5s ease-in-out infinite;
  }

  &__social {
    @apply flex flex-wrap items-center justify-center gap-x-7 mt-10;
    img {
      @apply relative duration-300 top-0;
    }
    a:hover {
      img {
        @apply -top-1;
      }
    }
  }
}
